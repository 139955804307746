import { create } from 'zustand';
import { SkillCategory } from '../types/SkillCategory';
import { Skill } from '../types/Skill';

type Store = {
	// States
	skillCategories: Array<SkillCategory>;
	skills: Array<Skill>;

	// Setters
	setSkillCategories: (newSkillCategories: Array<SkillCategory>) => void;
	setSkills: (newSkills: Array<Skill>) => void;

	// Getters
	skillCategoryBySkillCategoryId: (skillCategoryId: string) => SkillCategory | undefined;
	skillBySkillId: (skillId: string) => Skill | undefined;
};

const useAppStore = create<Store>((set, get) => ({
	// States
	skillCategories: [],
	skills: [],

	// Setters
	setSkillCategories: (newSkillCategories: Array<SkillCategory>) => set({ skillCategories: newSkillCategories }),
	setSkills: (newSkills: Array<Skill>) => set({ skills: newSkills }),

	// Getters
	skillCategoryBySkillCategoryId: (skillCategoryId: string) => get().skillCategories.find((skillCategory: SkillCategory) => skillCategory.skillCategoryId === skillCategoryId),
	skillBySkillId: (skillId: string) => get().skills.find((skill: Skill) => skill.skillId === skillId)
}));

export default useAppStore;