import './Footer.scss';
import LogoBlanc from "../../assets/icons/LogoBlanc.svg";
import { Section } from '../../types/Section';
import Logo from '../Logo/Logo';
import ContactBar from '../ContactBar/ContactBar';

const CREATION_YEAR = 2024;
const CURRENT_YEAR = new Date().getFullYear();

const COPYRIGHT = [
	{
		id: "1",
		string: `© ${CREATION_YEAR} ${CREATION_YEAR === CURRENT_YEAR ? "" : ` - ${CURRENT_YEAR}`} Damien PROCACCINO`
	},
	{
		id: "2",
		string: "Tous droits réservés"
	},
	{
		id: "3",
		string: "Icônes : Flaticon"
	}
];

type CopyrightInfo = {
	id: string;
	string: string;
}

type Props = {
	sections: Array<Section>;
}

function Footer({ sections }: Props): JSX.Element {
	return (
		<div className='flex flex-col Footer'>
			<div className='flex flex-col items-center Footer-Infos'>
				<Logo logoSrc={LogoBlanc} />
				<div className='flex flex-col Footer-Infos-Sections'>
					{sections.map((section: Section) =>	<a key={section.id} href={`#${section.id}`}>{section.label}</a>)}
				</div>
				<ContactBar />
			</div>
			<div className='flex flex-col Footer-Copyright'>
				{COPYRIGHT.map((copyrightInfo: CopyrightInfo) => <span key={copyrightInfo.id}>{copyrightInfo.string}</span>)}
			</div>
		</div>
	);
}

export default Footer;
