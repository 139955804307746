import './Logo.scss';
import { ReactSVG } from 'react-svg';

type Props = {
	logoSrc: string;
}

function Logo({ logoSrc }: Props): JSX.Element {
	return <ReactSVG src={logoSrc} className='Logo' />;
}

export default Logo;
