import './SectionSkills.scss';
import { SkillCategory as SkillCategoryType } from '../../types/SkillCategory';
import SectionLabel from '../SectionLabel/SectionLabel';
import SkillCategory from '../SkillCategory/SkillCategory';

type Props = {
	sectionId: string;
	sectionLabel: string;
	skillCategoriesToDisplayInSkills: Array<SkillCategoryType>;
}

function SectionSkills({ sectionId, sectionLabel, skillCategoriesToDisplayInSkills }: Props): JSX.Element {
	return (
		<div className='flex flex-col items-center Section-Skills' id={sectionId}>
			<SectionLabel label={sectionLabel} />
			<div className='flex Section-Skills-Content'>
				{skillCategoriesToDisplayInSkills
					.sort((skillCategory1: SkillCategoryType, skillCategory2: SkillCategoryType) => skillCategory1.order - skillCategory2.order)
					.map((skillCategory: SkillCategoryType) => (
						<SkillCategory
							key={skillCategory.skillCategoryId}
							skillCategory={skillCategory}
							skillCategoriesToDisplayInSkills={skillCategoriesToDisplayInSkills}
						/>
					))}
			</div>
		</div>
	);
}

export default SectionSkills;
