import './SectionProjects.scss';
import { Project as ProjectType } from '../../types/Project';
import ProjectsNav from '../ProjectsNav/ProjectsNav';
import Project from '../Project/Project';
import SectionLabel from '../SectionLabel/SectionLabel';

type Props = {
	sectionId: string;
	sectionLabel: string;
	projects: Array<ProjectType>;
	arePersonalProjects?: boolean;
}

function SectionProjects({ sectionId, sectionLabel, projects, arePersonalProjects }: Props): JSX.Element {
	const projectType = arePersonalProjects ? 'Personal' : 'Professional';

	return (
		<div className={`flex flex-col Section-Projects ${projectType}`} id={sectionId}>
			<SectionLabel label={sectionLabel} />
			{projects.length > 1 && <ProjectsNav projects={projects} />}
			<div className='flex flex-col Section-Projects-Projects'>
				{projects
					.sort((project1: ProjectType, project2: ProjectType) => project2.dateFrom.localeCompare(project1.dateFrom))
					.map((project: ProjectType) => (
						<Project
							key={project.id}
							project={project}
							projectType={projectType}
						/>
					))
				}
			</div>
		</div>
	);
}

export default SectionProjects;
