import './Skill.scss';
import { Skill as SkillType } from '../../types/Skill';

type Props = {
	skill: SkillType;
}

function Skill({ skill }: Props): JSX.Element {
	return (
		<div className='flex flex-col items-center Skill'>
			{skill.src && <img src={skill.src} alt={skill.skillLabel} />}
			<span>{skill.skillLabel}</span>
		</div>
	);
}

export default Skill;
