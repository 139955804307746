import './Project.scss';
import { Project as ProjectType } from '../../types/Project';
import { OrderedString } from '../../types/OrderedString';
import { ProjectRequiredSkillsByCategory } from '../../types/ProjectRequiredSkillsByCategory';
import Dates from "../../assets/icons/Project/Dates.svg";
import CompagnyLocation from "../../assets/icons/Project/CompagnyLocation.svg";
import RequiredSkills from "../../assets/icons/Project/RequiredSkills.svg";
import Before from "../../assets/icons/Project/Before.svg";
import Features from "../../assets/icons/Project/Features.svg";
import Around from "../../assets/icons/Project/Around.svg";
import Achievments from "../../assets/icons/Project/Achievments.svg";
import { ReactSVG } from 'react-svg';
import useAppStore from "../../stores/App";
import { SkillCategory } from '../../types/SkillCategory';
import { Skill } from '../../types/Skill';

const PROJECT_LABELS = {
	REQUIRED_SKILLS: "Compétences requises",
	BEFORE: "Prérequis",
	FEATURES: "Fonctionnalités",
	AROUND: "Cadre de travail",
	ACHIEVMENTS: "Réalisations"
} as const;

function translatedDate(YYYYMMDate: string): string {
	const split = YYYYMMDate.split("-");
	const YYYY = split[0];
	const date = new Date(split[1]);
	date.setMonth(parseInt(split[1]) - 1);
	const translatedMonth = new Intl.DateTimeFormat('fr-FR', { month: 'long' }).format(date);

	return `${translatedMonth} ${YYYY}`;
};

function dateTo(YYYYMMDate: string): string {
	if (YYYYMMDate !== "0") {
		return YYYYMMDate;
	}

	const now = new Date();
	const YYYY = now.getFullYear();
	let month = (now.getMonth() + 1).toString();

	if (month.length < 2) {
		month = `0${month}`;
	}

	return `${YYYY}-${month}`;
}

type NewProjectRequiredSkillsByCategory = {
	skillCategory: SkillCategory | undefined;
	skills: Array<Skill | undefined>;
}

type Props = {
	project: ProjectType;
	projectType: string;
}

const ProjectContentSection = (headerSrc: string, headerLabel: string, itemsElements: Array<NewProjectRequiredSkillsByCategory | OrderedString>, itemsClass: string = ""): JSX.Element => {
	return (
		<div className='flex flex-col Project-Content-Section'>
			<div className="flex items-center Project-Content-Section-Header">
				<ReactSVG src={headerSrc} />
				<span>{headerLabel}</span>
			</div>
			<div className={`grid Project-Content-Section-Items ${itemsClass}`}>
				{headerLabel === PROJECT_LABELS.REQUIRED_SKILLS ?
					(itemsElements as Array<NewProjectRequiredSkillsByCategory>)
						.sort((item1: NewProjectRequiredSkillsByCategory, item2: NewProjectRequiredSkillsByCategory) => (item1.skillCategory?.order || 0) - (item2.skillCategory?.order || 0))
						.map((newProjectRequiredSkillsByCategory: NewProjectRequiredSkillsByCategory, index: number) => (
							<span key={newProjectRequiredSkillsByCategory.skillCategory?.skillCategoryId || index}>
								{newProjectRequiredSkillsByCategory.skillCategory?.skillCategoryLabel} : {(newProjectRequiredSkillsByCategory.skills
									.sort((item1: Skill | undefined, item2: Skill | undefined) => (item1?.orderByCategoryId || 0) - (item2?.orderByCategoryId || 0))
									.map((skill: Skill | undefined) => skill?.skillLabel) || []).join(", ")}
							</span>
						)) :
					(itemsElements as Array<OrderedString>)
						.sort((item1: OrderedString, item2: OrderedString) => item1.order - item2.order)
						.map((item: OrderedString) => (
							<span key={item.order}>{item.string}</span>
						))
				}
			</div>
		</div>
	)
};

function Project({ project, projectType }: Props): JSX.Element {
	const { skillCategoryBySkillCategoryId, skillBySkillId } = useAppStore((state) => ({
		skillCategoryBySkillCategoryId: state.skillCategoryBySkillCategoryId,
		skillBySkillId: state.skillBySkillId
	}));

	const projectRequiredSkills = project.projectRequiredSkills.reduce((acc: Array<NewProjectRequiredSkillsByCategory>, current: ProjectRequiredSkillsByCategory) => {
		acc.push({
			skillCategory: skillCategoryBySkillCategoryId(current.skillCategoryId),
			skills: current.skillIds.map((skillId: string) => skillBySkillId(skillId))
		})

		return acc;
	}, []);

	return (
		<div className={`flex-col Project Project-${projectType}`} id={project.href}>
			<div className="flex flex-col lg:flex-row Project-Header">
				<div className="flex flex-col Project-Header-Left">
					{project.personal && <span className='Project-Header-Name'>{project.personal.name}</span>}
					{project.professional && <span className='Project-Header-Job'>{project.professional.job}</span>}
					<span className='Project-Header-Subject'>{project.subject}</span>
				</div>
				<div className="flex flex-col lg:justify-end lg:items-end Project-Header-Right">
					<div className="flex items-center Project-Header-Dates">
						<ReactSVG src={Dates} />
						<span>{translatedDate(project.dateFrom)} - {translatedDate(dateTo(project.dateTo))}</span>
					</div>
					{project.professional && (
						<div className="flex items-center Project-Header-Compagny-Location">
							<ReactSVG src={CompagnyLocation} />
							<span>{project.professional.compagny} - {project.professional.location}</span>
						</div>
					)}
				</div>
			</div>
			<div className="flex flex-col Project-Content">
				<div className='flex flex-col lg:flex-row'>
					{ProjectContentSection(RequiredSkills, PROJECT_LABELS.REQUIRED_SKILLS, projectRequiredSkills)}
					{ProjectContentSection(Before, PROJECT_LABELS.BEFORE, project.before)}
				</div>
				{project.personal && ProjectContentSection(Features, PROJECT_LABELS.FEATURES, project.personal.features, "Two-Col")}
				{project.professional && ProjectContentSection(Around, PROJECT_LABELS.AROUND, project.professional.around, "Two-Col")}
				{ProjectContentSection(Achievments, PROJECT_LABELS.ACHIEVMENTS, project.achievments, "Two-Col")}
			</div>
		</div>
	);
}

export default Project;
