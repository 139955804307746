import './Loader.scss';

const MESSAGES = {
	PENDING: "Chargement des données en cours...",
	ERROR: "Echec du chargement des données..."
};

type Props = {
	isPending: boolean;
	isError: boolean;
}

function Loader({ isPending, isError }: Props): JSX.Element {
	return (
		<div className="flex flex-col justify-center items-center Loader">
			{isError && (
				<>
					<span className='Loader-Message'>{MESSAGES.ERROR}</span>
					<div className="Button Blue">
						<a href={window.location.href}>Recharger</a>
					</div>
				</>
			)}
			{isPending && (
				<>
					<span className='Loader-Message'>{MESSAGES.PENDING}</span>
					<div className="flex justify-between Loader-Animation">
						<div />
						<div />
						<div />
					</div>
				</>
			)}
		</div>
	);
}

export default Loader;
