import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
	apiKey: "AIzaSyAm5zWmSPV5_3nmMEWZ8BCykXlDn2MjaGI",
	authDomain: "mycvapp-795f7.firebaseapp.com",
	projectId: "mycvapp-795f7",
	storageBucket: "mycvapp-795f7.appspot.com",
	messagingSenderId: "923551622687",
	appId: "1:923551622687:web:8dcba1741dc547c1fd572a"
};

const app = initializeApp(firebaseConfig);

export const database = getFirestore(app);